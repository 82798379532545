<template>
  <div>
    <div class="d-flex justify-content-end m-3 no-print">
      <b-button variant="primary" @click="print()"> Imprimir </b-button>
    </div>

    <div class="m-3">
      <div
        v-for="(periods, date) in groupedByDateAndPeriod"
        :key="date"
        class="mb-5"
      >
        <h4 class="font-weight-bold" style="font-size: 1.5rem">
          Data: {{ date | datePT() }}
        </h4>

        <div v-for="(items, period) in periods" :key="period" class="mb-4">
          <h5 class="text-primary">{{ period }}</h5>
          <b-table
            :no-border-collapse="true"
            class="position-relative"
            style="border: 2px solid #dee2e6"
            :items="items"
            responsive
            :fields="tableColumns"
            primary-key="id"
            show-empty
            striped
            sort-by="start_time"
            :busy="loading"
            hover
            id="printMe"
            empty-text="Nenhuma aula cadastrada até o momento."
          >
            <template #head(devolution_pilots)="data">
              <span v-html="data.label"></span>
            </template>

            <template #cell(discipline_title)="{ item }">
              <b-media vertical-align="center">
                <div class="d-block">
                  {{ item.discipline_title }}
                </div>
                <small style="font-size: 0.9rem" class="text-secondary">{{
                  item.course_title
                }}</small>
              </b-media>
            </template>

            <template #cell(date)="{ item }">
              {{ item.date | datePT() }}
            </template>

            <template #cell(classrooms)="{ item }">
              <span v-for="(element, index) in item.classrooms" :key="index">
                {{ element.classroom_name }}
              </span>
            </template>

            <template #cell(arrival_time)="{ item }">
              <span v-if="item.arrival_time">
                {{ item.arrival_time }} às {{ item.departure_time }}
              </span>
            </template>
            <template #cell(devolution_pilots)="{ item }">
              <div v-if="item.devolution_pilots">
                <span class="text-dark" v-if="item.devolution_pilots.code === 1"
                  >Ok</span
                >
              </div>
            </template>

            <template #cell(signature)="{ item }">
              <span v-if="item.signature">
                {{ item.signature | datePT(true) }}
              </span>
            </template>

            <template #cell(start_time)="{ item }">
              <span v-if="item.start_time">
                {{ item.start_time }} às {{ item.end_time }}
              </span>
            </template>

            <template #cell(real_workload)="{ item }">
              <span v-if="item.real_workload">
                {{ convertDecimalHoursToTime(item.real_workload) }}
              </span>
            </template>

            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner
                  small
                  class="align-middle"
                  style="margin-right: 3px"
                ></b-spinner>
                <strong> carregando...</strong>
              </div>
            </template>
          </b-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BButton, BTable, BMedia, BSpinner } from "bootstrap-vue";
import moment from "moment";

export default {
  layout: "blank",
  components: {
    BButton,
    BTable,
    BMedia,
    BSpinner,
  },
  computed: {
    groupedByDateAndPeriod() {
      const periods = {
        Manhã: [0, 12],
        Tarde: [12, 18],
        Noite: [18, 24],
      };

      const periodOrder = ["Manhã", "Tarde", "Noite"];

      const group = {};

      for (const item of this.dataItems) {
        const date = moment(item.date).format("YYYY-MM-DD");
        const startHour = parseInt(item.start_time?.split(":")[0] || 0, 10);

        const period =
          Object.entries(periods).find(
            ([_, [start, end]]) => startHour >= start && startHour < end
          )?.[0] || "Turno não definido";

        if (!group[date]) group[date] = {};
        if (!group[date][period]) group[date][period] = [];

        group[date][period].push(item);
      }

      // Ordenar os períodos dentro de cada data
      const orderedGroup = {};
      Object.keys(group)
        .sort()
        .forEach((date) => {
          orderedGroup[date] = {};

          periodOrder.forEach((period) => {
            if (group[date][period]) {
              orderedGroup[date][period] = group[date][period];
            }
          });

          // Inclui qualquer período que não esteja na ordem padrão
          Object.keys(group[date]).forEach((period) => {
            if (!periodOrder.includes(period)) {
              orderedGroup[date][period] = group[date][period];
            }
          });
        });

      return orderedGroup;
    },
  },
  data() {
    return {
      filters: {},
      dataItems: [],
      loading: false,
      tableColumns: [
        {
          key: "teacher_full_name",
          label: "Professor",
          sortable: false,
          class: "text-left",
          thStyle: "width: 200px;display: table-cell;",
        },
        {
          key: "discipline_title",
          label: "Disciplina/Curso",
          sortable: false,
          thStyle: "width: 340px;display: table-cell;",
        },
        {
          key: "date",
          label: "Data",
          sortable: false,
          class: "text-center",
          thStyle: "width: 80px",
        },
        {
          key: "classrooms",
          label: "Turmas",
          sortable: false,
          class: "text-center",
          thStyle: "width: 80px",
        },
        {
          key: "unit_name",
          label: "Unidade",
          sortable: true,
          class: "text-center",
          thStyle: "width: 120px",
        },
        {
          key: "start_time",
          label: "Horário",
          sortable: true,
          class: "text-center",
          thStyle: "width: 120px",
        },
        {
          key: "signature",
          label: "Assinado",
          sortable: false,
          class: "text-center",
          thStyle: "width: 115px;display: table-cell;",
        },
        {
          key: "arrival_time",
          label: "Período",
          sortable: false,
          class: "text-center",
          thStyle: "width: 80px",
        },
        {
          key: "real_workload",
          label: "Tempo",
          sortable: false,
          class: "text-center",
          thStyle: "width: 80px",
        },
        {
          key: "devolution_pilots",
          label: "<i class='bi bi-pencil'></i>",
          sortable: false,
          class: "text-center",
          thStyle: "width: 80px",
        },
      ],
    };
  },
  beforeDestroy() {
    localStorage.removeItem("printData");
  },
  mounted() {
    const saved = localStorage.getItem("printData");

    console.log("saved", saved);
    if (saved) {
      this.filters = JSON.parse(saved);
      this.getData().then(() => {
        this.print();
      });
    }
  },
  methods: {
    print() {
      setTimeout(() => {
        window.print();
      }, 1500);
    },
    convertDecimalHoursToTime(decimalHours) {
      const hours = Math.floor(decimalHours);
      const minutes = Math.round((decimalHours - hours) * 60);
      if (hours === 0) return `${minutes}min`;
      if (minutes === 0) return `${hours}h`;

      return `${hours}h ${minutes}min`;
    },
    async getData() {
      this.loading = true;
      this.$store
        .dispatch("Grid/getAllLessonsTeacherPoints", this.filters)
        .then((data) => {
          if (data) {
            this.dataItems = data.data;
          }
        })
        .finally(() => {
          this.btnSearch = false;
          this.loading = false;
        });
    },
  },
};
</script>
<style>
@media print {
  @page {
    size: landscape;
  }

  .no-print {
    display: none !important;
  }

  .table th,
  .table td {
    border: 1px solid #dee2e6 !important;
  }
}
</style>
