var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "d-flex justify-content-end m-3 no-print" },
      [
        _c(
          "b-button",
          {
            attrs: { variant: "primary" },
            on: {
              click: function ($event) {
                return _vm.print()
              },
            },
          },
          [_vm._v(" Imprimir ")]
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "m-3" },
      _vm._l(_vm.groupedByDateAndPeriod, function (periods, date) {
        return _c(
          "div",
          { key: date, staticClass: "mb-5" },
          [
            _c(
              "h4",
              {
                staticClass: "font-weight-bold",
                staticStyle: { "font-size": "1.5rem" },
              },
              [_vm._v(" Data: " + _vm._s(_vm._f("datePT")(date)) + " ")]
            ),
            _vm._l(periods, function (items, period) {
              return _c(
                "div",
                { key: period, staticClass: "mb-4" },
                [
                  _c("h5", { staticClass: "text-primary" }, [
                    _vm._v(_vm._s(period)),
                  ]),
                  _c("b-table", {
                    staticClass: "position-relative",
                    staticStyle: { border: "2px solid #dee2e6" },
                    attrs: {
                      "no-border-collapse": true,
                      items: items,
                      responsive: "",
                      fields: _vm.tableColumns,
                      "primary-key": "id",
                      "show-empty": "",
                      striped: "",
                      "sort-by": "start_time",
                      busy: _vm.loading,
                      hover: "",
                      id: "printMe",
                      "empty-text": "Nenhuma aula cadastrada até o momento.",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "head(devolution_pilots)",
                          fn: function (data) {
                            return [
                              _c("span", {
                                domProps: { innerHTML: _vm._s(data.label) },
                              }),
                            ]
                          },
                        },
                        {
                          key: "cell(discipline_title)",
                          fn: function (ref) {
                            var item = ref.item
                            return [
                              _c(
                                "b-media",
                                { attrs: { "vertical-align": "center" } },
                                [
                                  _c("div", { staticClass: "d-block" }, [
                                    _vm._v(
                                      " " + _vm._s(item.discipline_title) + " "
                                    ),
                                  ]),
                                  _c(
                                    "small",
                                    {
                                      staticClass: "text-secondary",
                                      staticStyle: { "font-size": "0.9rem" },
                                    },
                                    [_vm._v(_vm._s(item.course_title))]
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                        {
                          key: "cell(date)",
                          fn: function (ref) {
                            var item = ref.item
                            return [
                              _vm._v(
                                " " + _vm._s(_vm._f("datePT")(item.date)) + " "
                              ),
                            ]
                          },
                        },
                        {
                          key: "cell(classrooms)",
                          fn: function (ref) {
                            var item = ref.item
                            return _vm._l(
                              item.classrooms,
                              function (element, index) {
                                return _c("span", { key: index }, [
                                  _vm._v(
                                    " " + _vm._s(element.classroom_name) + " "
                                  ),
                                ])
                              }
                            )
                          },
                        },
                        {
                          key: "cell(arrival_time)",
                          fn: function (ref) {
                            var item = ref.item
                            return [
                              item.arrival_time
                                ? _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(item.arrival_time) +
                                        " às " +
                                        _vm._s(item.departure_time) +
                                        " "
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                        {
                          key: "cell(devolution_pilots)",
                          fn: function (ref) {
                            var item = ref.item
                            return [
                              item.devolution_pilots
                                ? _c("div", [
                                    item.devolution_pilots.code === 1
                                      ? _c(
                                          "span",
                                          { staticClass: "text-dark" },
                                          [_vm._v("Ok")]
                                        )
                                      : _vm._e(),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                        {
                          key: "cell(signature)",
                          fn: function (ref) {
                            var item = ref.item
                            return [
                              item.signature
                                ? _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("datePT")(item.signature, true)
                                        ) +
                                        " "
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                        {
                          key: "cell(start_time)",
                          fn: function (ref) {
                            var item = ref.item
                            return [
                              item.start_time
                                ? _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(item.start_time) +
                                        " às " +
                                        _vm._s(item.end_time) +
                                        " "
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                        {
                          key: "cell(real_workload)",
                          fn: function (ref) {
                            var item = ref.item
                            return [
                              item.real_workload
                                ? _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.convertDecimalHoursToTime(
                                            item.real_workload
                                          )
                                        ) +
                                        " "
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                        {
                          key: "table-busy",
                          fn: function () {
                            return [
                              _c(
                                "div",
                                {
                                  staticClass: "text-center text-primary my-2",
                                },
                                [
                                  _c("b-spinner", {
                                    staticClass: "align-middle",
                                    staticStyle: { "margin-right": "3px" },
                                    attrs: { small: "" },
                                  }),
                                  _c("strong", [_vm._v(" carregando...")]),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                ],
                1
              )
            }),
          ],
          2
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }